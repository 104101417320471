// import the component
import { Button, Input, Flex } from 'theme-ui';
import React, { Component } from "react";

class SimpleForm extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            status: "initial"
        };
        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async onSubmitForm(event) {
        event.preventDefault();
        try {
            this.setState({
                status: "sending"
            });
            const result = await fetch(
                "https://blog.waterslide.app/members/api/send-magic-link/",
                {
                    method: "POST", // or 'PUT'
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ email: this.state.email, emailType: "subscribe" })
                }
            );

            const r = await result.text();

            this.setState({
                status: r
            });

            console.log(r);
            console.log(this.state.status)


        } catch (e) {
            this.setState({
                status: "Bad Request."
            });
        } finally {
        }
    };

    render() {
        // const { items } = this.state;
        let status = this.state.status;

        return (
            <Flex sx={{ ml: 3, mr: 0, flexDirection: "row" }}>
                {status === "sending" && <Input
                    sx={{ borderRadius: 25, textAlign: "center", bg: "primary", color: "white", fontSize: "16px", px: "32px", py: "12px" }}
                    type="text"
                    value="Sending..."
                    disabled
                />
                }
                {status === "Bad Request." && <Input
                    sx={{ borderRadius: 25, textAlign: "center", bg: "warning", color: "white", fontSize: "16px", px: "32px", py: "12px" }}
                    type="text"
                    value="Please try again later"
                    disabled
                />
                }
                {status === "Internal Server Error." && <Input
                    sx={{ borderRadius: 25, textAlign: "center", bg: "warning", color: "white", fontSize: "16px", px: "32px", py: "12px" }}
                    type="text"
                    value="Invalid address"
                    disabled
                />
                }
                {status === "Created." && <Input
                    sx={{ borderRadius: 25, textAlign: "center", bg: "success", color: "white", fontSize: "16px", px: "32px", py: "12px" }}
                    type="text"
                    value="Thank you!"
                    disabled
                />
                }
                { status === "initial" && (
                    <>
                        <Input
                            sx={{ borderRadius: 25, paddingLeft: 3, fontSize: "16px", width: "170px" }}
                            type="email" required
                            placeholder="Stay up to date"
                            name="email"
                            value={this.state.email}
                            onChange={this.onInputchange}

                        />
                        <Button onClick={this.onSubmitForm} disabled={!this.state.email}>
                            Sign Up
                    </Button>
                    </>
                )}
            </Flex>
        );
    }
}

export const GhostForm = () => {
    return (
        // none hides the form when we switch to mobile
        <Flex sx={{ alignItems: "center" }}>
                <Flex sx={{ ml: 3, mr: 0, flexDirection: "column" }}>
                    <SimpleForm></SimpleForm>
                </Flex>
            </Flex>
    );
};


