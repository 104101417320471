import React from "react";
import { Box, Card, Container, Heading } from "theme-ui";
import { GhostForm } from "../components/GhostForm";


export const Newsletter: React.FC = () => {

    return (
        <Container variant="columns">
            <Container variant="left">
                <Card>
                    <Heading>
                        Sign up to our newsletter
                    </Heading>
                    <Box sx={{ p: [2, 3]}}>
                        <GhostForm />
                    </Box>
                </Card>
            </Container>
        </Container>
    );
};
