import React from "react";
import { Container } from "theme-ui";

import { DfinityLogo } from "./DfinityLogo";

const logoHeight = "64px";

export const Footer: React.FC = ({ children }) => {

    return (
        <Container variant="footer">
            {/* {children} */}
            <Container>
                <a href="https://t.me/waterslide_app" title="Telegram" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" style={{
                        height: "35px", width: "35px"
                    }}>
                        <path d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80" />
                        <path d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035" />
                        <path
                            d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258" />
                    </svg>
                </a>
                <a href="https://twitter.com/waterslide_app" title="Twitter" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{ height: "22px", width: "22px", margin: "5px 20px" }}>
                        <path
                            d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z" />
                    </svg>
                </a>
            </Container>
            <Container sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexWrap: "nowrap", fontSize: ["12px", "22px"] }}>
                <div style={{ textAlign: "center" }}>
                    Hosted on
                   </div>
                <DfinityLogo height={logoHeight} />
            </Container >
        </Container>
    );
};
